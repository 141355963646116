<template>
  <div>
    <v-data-table
      :headers="cabecalho"
      :items="apuracoes"
      :options.sync="pagination"
      :server-items-length="totalPage"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [10,20,25],
      }">
      <template v-slot:[`item.acoes`]="{ item }">
        <v-menu bottom
                origin="center center"
                transition="scale-transition">
          <template v-slot:activator="{ on }">
            <v-btn
                v-on="on"
                :id="'more_vert_' + item.id"
                icon>
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="detalharAcao(item)">
              <v-list-item-action class="mr-4">
                <v-icon>details</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $tc('label.detalhe', 2) }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="abrirModalGerarNd(item)">
              <v-list-item-action class="mr-4">
                <v-icon>paid</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $tc('label.ver_nota_debito', 2) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:[`item.apuracao_total`]="{ item }">
        {{ getMoney(item.apuracao_total) }}
        <h4 v-if="item.possui_valor_maximo_nd" class="orange--text">
           {{ '%execução: ' + getPercent(item.apuracao_total_execucao * 100) }}
        </h4>
      </template>
      <template v-slot:[`item.vlr_conf_apuracao_original`]="{ item }">
        {{ getMoney(item.vlr_conf_apuracao_original) }}
        <h4 v-if="item.possui_valor_maximo_nd" class="orange--text">
           {{ '%execução: ' + getPercent(item.vlr_conf_apuracao_execucao * 100)}}
        </h4>
      </template>
      <template v-slot:[`item.teto_acao`]="{ item }">
        {{ item.possui_valor_maximo_nd ? getMoney(item.teto_acao) : ' - ' }}
        <h5 v-if="item.is_calculo_produto_nd_maximo" class="orange--text">
          {{ $tc('label.agrupamento_por_produto', 2)}}
        </h5>
        <h5 v-else-if="item.is_calculo_campanha_nd_maximo" class="orange--text">
          {{ $tc('label.sobre_teto_da_campanha', 2)}}
        </h5>
      </template>
      <template v-slot:[`item.cod_fornecedor_acao`]="{ item }">
        {{ item.cod_fornecedor_acao ? item.cod_fornecedor_acao : ' - ' }}
      </template>
      <template v-slot:[`item.cod_acao_fornecedor`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon class="mx-0" v-on="on" @click="abrirModalEditarIdFornecedor(item)">
              <v-icon>feed</v-icon>
            </v-btn>
          </template>
          <span>{{ item.cod_fornecedor_acao ? item.cod_fornecedor_acao : ' - ' }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.verba_nao_capturada`]="{ item }">
        {{ item.possui_valor_maximo_nd ? getMoney(item.verba_nao_capturada) : ' - ' }}
      </template>
      <template v-slot:[`item.verba_acima_do_teto`]="{ item }">
        {{ item.possui_valor_maximo_nd ? getMoney(item.verba_acima_do_teto) : ' - ' }}
      </template>
      <template v-slot:[`item.nota_debito`]="{ item }">
        {{ getMoney(item.nota_debito) }}
      </template>
      <template v-slot:[`item.valor_recebimento_pendente`]="{ item }">
        <tr>
          <td style="white-space: nowrap">
            {{ getMoney(item.valor_recebimento_pendente) }}
          </td>
          <td v-if="item.valor_recebimento_pendente > 0">
            <popover-nd
                :cod-acao="item.cod_acao">
            </popover-nd>
          </td>
        </tr>
      </template>
      <template v-slot:[`item.possui_gatilho`]="{ item }">
        <tr>
          <td style="white-space: nowrap">
            {{ !!item.tipo_gatilho ? $t('label.sim') : $t('label.nao') }}
          </td>
          <td v-if="!!item.tipo_gatilho">
            <tooltip-ajuda color="black" :mensagem="`${$t('message.ajuda.apuracao_acao.possui_gatilho')}`" />
          </td>
        </tr>
      </template>
      <template v-slot:[`item.detalhar`]="{ item }">
        <v-btn
          elevation="2"
          color="primary"
          @click="detalhar(item)"
          class="text-capitalize white--text"
          small
        >
          {{ $tc('label.ver_detalhes' )}}
        </v-btn>
      </template>
    </v-data-table>

    <apuracao-acao-lista-modal-gerar-nd
      ref="modalGerarNd"
      :somente-leitura="somenteLeitura"
      v-if="acaoModal"
      v-model="acaoModal"
      :abrir-modal="abrirModalND"
      @ApuracaoAcaoListaModalGerarNd__fechar="fecharModalND"
      @ApuracaoAcaoListaModalGerarNd__recarregarApuracao="buscarApuracoes"
      @ApuracaoAcaoListaModalGerarNd__atualizarResumoAcao="atualizarResumoAcao">
    </apuracao-acao-lista-modal-gerar-nd>
    <acompanhamento
        ref="modalAcompanhamento"
        :fluxo="statusPassos"
        :entidade="'APURACAO'"/>
    <v-dialog v-model="modalEditarIdFornecedor"
        width="800px"
        persistent>
      <v-card>
        <v-card-title>
          <span> {{ $tc('title.altere_id_acao_fornecedor') }}</span>
        </v-card-title>
        <v-card-text>
          <span><b>{{ $t('title.atencao_alerta_modal_alteracao') }}</b>{{ $t('message.alerta_alteracao_id_fornecedor') }}</span>
        </v-card-text>

        <v-col cols="12">
          <v-text-field
            class="mx-2"
            v-model="editIdFornecedor"
            :disabled="!validaStatusAlteracaoIdAcaoFornecedor()"
            :label="`${$t('label.id')} *`"
            maxlength="50"
            required
            >
          </v-text-field>
        </v-col>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn id="cancel" color="primary darken-1" @click="fecharModalIdFornecedor" text>
            {{ $t('label.cancelar') }}
          </v-btn>
          <v-btn
          :disabled="!editIdFornecedor.length"
          text
          color="primary darken-1"
          @click="salvarIdFornecedor()">
            {{ $t('label.salvar') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ApuracaoAcaoListaModalGerarNd from '@/produto/spa/apuracao-acao-lista/ApuracaoAcaoListaModalGerarNd';
import { getMoney, getPercent } from '../../common/functions/helpers';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import Status from '../../shared-components/Status';
import Acompanhamento from '../../shared-components/workflow/Acompanhamento';
import PopoverNd from './PopoverNd';
import PopoverRecebimentoPendente from './PopoverRecebimentoPendente';
import TooltipAjuda from '../../shared-components/TooltipAjuda';

export default {
  name: 'ApuracaoAcaoListaTabela',
  components: {
    ApuracaoAcaoListaModalGerarNd,
    Status,
    Acompanhamento,
    PopoverNd,
    PopoverRecebimentoPendente,
    TooltipAjuda,
  },
  computed: {
    ...mapGetters('filtros', [
      'filtroApuracaoAcao',
    ]),
    ...generateComputed('APU_ACAO', [
      'canEdit',
    ]),
  },
  props: {
    categoria: {
      type: Object,
      default: null,
    },
    panelExpandido: Array,
  },
  watch: {
    pagination: {
      handler() {
        if (this.mesmaPagina(this.pagination, this.lastPagination)) {
          return;
        }
        this.lastPagination = this.pagination;
        this.buscarApuracoes();
      },
      deep: true,
    },
  },
  data() {
    return {
      apuracaoAcaoListagemResource: this.$api.apuracaoAcaoVerbaVariavelListagem(this.$resource),
      workflowApuracaoAcaoResource: this.$api.workflowApuracaoAcao(this.$resource),
      apuracaoAcaoResource: this.$api.apuracaoAcaoEdicao(this.$resource),
      somenteLeitura: true,
      totalPage: 0,
      acaoModal: null,
      abrirModalND: false,
      pagination: {},
      lastPagination: {},
      statusPassos: {},
      apuracoes: [],
      filtrosAplicados: {},
      modalEditarIdFornecedor: false,
      editIdFornecedor: '',
      statusApuracoes: '',
      codAcaoApuracaoSelecionada: null,
      cabecalho: [
        {
          text: this.$tc('label.acoes', 1), value: 'acoes', sortable: false, width: '2%',
        },
        {
          text: this.$tc('label.id', 1), value: 'cod_acao', sortable: true, width: '2%', class: 'nowrap',
        },
        {
          text: this.$tc('label.fornecedor', 1), value: 'foco_acao', sortable: true, width: '10%', class: 'nowrap',
        },
        {
          text: this.$tc('label.codigo', 1), value: 'cod_campanha', sortable: true, width: '13%', class: 'nowrap',
        },
        {
          text: this.$tc('label.cod_fornecedor_acao', 1), value: 'cod_acao_fornecedor', sortable: true, width: '13%', class: 'nowrap',
        },
        {
          text: this.$tc('label.inicio_vigencia', 1), value: 'data_inicio', sortable: true, width: '3%', class: 'nowrap',
        },
        {
          text: this.$tc('label.fim_vigencia', 1), value: 'data_fim', sortable: true, width: '3%', class: 'nowrap',
        },
        {
          text: this.$tc('label.apuracao_total', 1), value: 'apuracao_total', sortable: true, width: '7%', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.teto_acao', 1), value: 'teto_acao', sortable: true, width: '8%', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.verba_nao_capturada', 1), value: 'verba_nao_capturada', sortable: true, width: '9%', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.verba_acima_do_teto', 1), value: 'verba_acima_do_teto', sortable: true, width: '9%', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.recebimento_pendente', 1), value: 'valor_recebimento_pendente', sortable: true, width: '12%', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.nota_debito', 1), value: 'nota_debito', sortable: true, width: '8%', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.possui_gatilho', 1), value: 'possui_gatilho', sortable: true, width: '10%', cellClass: 'nowrap',
        },
        {
          text: '', value: 'detalhar', sortable: false, width: '10%',
        },
      ],
    };
  },
  methods: {
    getMoney,
    getPercent,
    mesmaPagina(p1, p2) {
      return p1.page === p2.page && p1.itemsPerPage === p2.itemsPerPage && p1.sortBy.length === 0;
    },
    buscarApuracoes(atualizarAcaoAbertaModal = false) {
      this.apuracoes = [];
      const filtros = { ...this.filtroApuracaoAcao };
      filtros.numeroPagina = this.pagination.page;
      filtros.tamanhoPagina = this.pagination.itemsPerPage;
      filtros.idCategoria = this.categoria.id;
      filtros.asc = !this.pagination.sortDesc[0];
      const [colunaOrdenacao] = this.pagination.sortBy;
      filtros.colunaOrdenacao = colunaOrdenacao;
      this.filtrosAplicados = { ...filtros };
      this.apuracaoAcaoListagemResource.buscarApuracoes(filtros)
        .then((response) => {
          this.apuracoes = response.data.resposta;
          this.totalPage = response.data.quantidadeRegistrosPagina;

          if (atualizarAcaoAbertaModal) {
            const acao = this.apuracoes.find((apuracao) => apuracao.cod_acao === this.acaoModal.cod_acao);
            this.acaoModal = acao;
          }
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    abrirModalGerarNd(acao) {
      this.acaoModal = acao;
      setTimeout(() => { this.abrirModalND = true; }, 2E2);
    },
    fecharModalND() {
      this.abrirModalND = false;
      setTimeout(() => { this.acaoModal = null; }, 2E2);
    },
    abrirModalEditarIdFornecedor(apuracao) {
      this.statusApuracoes = apuracao.status_apuracoes;
      this.codAcaoApuracaoSelecionada = apuracao.cod_acao;
      setTimeout(() => { this.modalEditarIdFornecedor = true; }, 2E2);
    },
    fecharModalIdFornecedor() {
      this.codAcaoApuracaoSelecionada = null;
      this.editIdFornecedor = '';
      this.statusApuracoes = '';
      this.modalEditarIdFornecedor = false;
    },
    salvarIdFornecedor() {
      const params = {
        idAcao: this.codAcaoApuracaoSelecionada,
        idAcaoFornecedor: this.editIdFornecedor,
      };
      this.apuracaoAcaoResource.salvarIdAcaoFornecedor(params, params)
        .then(() => {
          this.apuracoes.forEach((apuracao) => {
            if (apuracao.cod_acao === this.codAcaoApuracaoSelecionada) {
              apuracao.cod_fornecedor_acao = this.editIdFornecedor;
            }
          });
          this.fecharModalIdFornecedor();
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    validaStatus(item) {
      return item.status_apuracao === 'AGUARDANDO_APURACAO' || item.status_apuracao === 'APURACAO_PREVIA';
    },
    validaStatusAlteracaoIdAcaoFornecedor() {
      const listaStatus = this.statusApuracoes.split(',');
      return listaStatus.some((status) => ['AGUARDANDO_APURACAO', 'EM_ANALISE', 'APURACAO_PREVIA'].includes(status));
    },
    mostrarAcompanhamento(item) {
      return item.status_apuracao !== 'AGUARDANDO_APURACAO' && item.status_apuracao !== 'APURACAO_PREVIA' && item.status_apuracao !== 'CANCELADO';
    },
    detalhar(detalhe) {
      const routeName = 'apuracaoAcaoDetalhesNova';
      const idAcao = detalhe.cod_acao;
      const manterExpandido = this.panelExpandido;
      const { filtrosAplicados } = this;
      const resumoExec = {
        id: detalhe.cod_acao,
        descFornecedor: detalhe.nome_grupo_fornecedor ? detalhe.nome_grupo_fornecedor : detalhe.nome_fornecedor,
        focoAcao: detalhe.foco_acao,
        dtInicioVigencia: detalhe.data_inicio,
        dtFimVigencia: detalhe.data_fim,
        vlrApuracaoPrevia: detalhe.apuracao_previa,
        vlrApuracaoPreviaExecucao: detalhe.apuracao_previa_execucao,
        vlrApuracaoTotalExecucao: detalhe.apuracao_total_execucao,
        vlrApuracaoFinal: detalhe.vlr_conf_apuracao,
        vlrApuracaoTotal: detalhe.apuracao_total,
        vlrApuracaoFinalOriginal: detalhe.vlr_conf_apuracao_original,
        vlrApuracaoFinalExecucao: detalhe.vlr_conf_apuracao_execucao,
        vlrTetoAcao: detalhe.teto_acao,
        vlrVerbaNaoCapturada: detalhe.verba_nao_capturada,
        vlrVerbaAcimaDoTeto: detalhe.verba_acima_do_teto,
        status: detalhe.status_apuracao,
        tipoCalculo: detalhe.tipo_calculo,
        temGatilho: !!detalhe.tipo_gatilho,
        tipoGatilho: detalhe.tipo_gatilho,
        possuiValorMaximoNd: detalhe.possui_valor_maximo_nd,
        idGrupoFornecedor: detalhe.id_grupo_fornecedor,
        notaDebito: detalhe.nota_debito,
        valorRecebimentoPendente: detalhe.valor_recebimento_pendente,
        possuiNd: detalhe.possui_nd,
        tipoCalculoValorMaximoNd: detalhe.tipo_calculo_valor_maximo_nd,
      };
      this.$router.push({
        name: routeName,
        params: {
          idAcao, resumoExec, manterExpandido, from: 'apuracao-acao', filtrosAplicados,
        },
      });
    },
    detalharAcao(acao) {
      let routeName = 'detalharAcao';
      if (this.isTemplateVerbaVariavel(acao)) {
        routeName = 'detalharAcaoVerbaVariavel';
      }
      this.$router.push({ name: routeName, params: { idAcao: acao.cod_acao, from: 'apuracao-acao' } });
    },
    isTemplateVerbaVariavel(acao) {
      const { template } = acao;
      return template === 'VERBA_VARIAVEL';
    },
    atualizarResumoAcao() {
      const atualizarAcaoAbertaModal = true;
      this.buscarApuracoes(atualizarAcaoAbertaModal);
    },
  },
};
</script>
<style>
</style>
